import lozad from 'lozad';
import 'bootstrap/dist/js/bootstrap.bundle';
import Swiper from "swiper";
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import Swal from 'sweetalert2';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/l10n/uk';
import intlTelInput from 'intl-tel-input';
import 'lite-youtube-embed';

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function date_picker_init() {
    flatpickr('.datepicker__input', {
        dateFormat: 'Y-m-d',
        minDate: 'today',
        locale: languageCode
    });
}

function filterBlogsByHash(hash) {
    const allBlogs = document.querySelectorAll('.blog__card');
    const navItems = document.querySelectorAll('.blog__nav li');

    navItems.forEach(item => item.classList.remove('active'));

    if (hash) {
        allBlogs.forEach(blog => blog.style.display = 'none');
        const matchingBlogs = document.querySelectorAll(`.section-${hash}`);
        matchingBlogs.forEach(blog => blog.style.display = 'block');

        const activeNavItem = document.querySelector(`.blog__nav li a[href$="#${hash}"]`)?.parentElement;
        if (activeNavItem) activeNavItem.classList.add('active');
    } else {
        allBlogs.forEach(blog => blog.style.display = 'block');
        const allNavItem = document.getElementById('is-main');
        if (allNavItem) allNavItem.classList.add('active');
    }
}

function intlTelInputInit() {
    const inputs = document.querySelectorAll(".phone_intl");
    inputs.forEach((input) => {
        if (!input.dataset.intlTelInputInitialized) {
            const iti = intlTelInput(input, {
                initialCountry: "UA",
                loadUtilsOnInit: () => import("intl-tel-input/utils"),
            });

            input.dataset.intlTelInputInitialized = "true";

            const form = input.closest("form");
            if (form) {
                form.addEventListener("submit", function (event) {
                    if (iti.isValidNumber()) {
                        input.value = iti.getNumber();
                    } else {
                        alert(phoneAlert);
                        event.preventDefault();
                    }
                });
            }
        }
    });
}

(function ($) {
    jQuery(document).ready(function ($) {

        const observer = lozad();
        observer.observe();

        intlTelInputInit();

        filterBlogsByHash(window.location.hash.substring(1));

        $(window).on('hashchange', function () {
            filterBlogsByHash(window.location.hash.substring(1));
        });


        jQuery(document.body).on('click', '.quantity_swal__plus', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var input = $(this).closest('.quantity_swal').find('input[type="number"]');
            var min = input.attr('min');
            var max = input.attr('max');
            var oldValue = parseFloat(input.val());
            if (oldValue >= max) {
                var newVal = oldValue;
            } else {
                var newVal = oldValue + 1;
            }
            input.val(newVal);
            input.trigger("change");
        });
        jQuery(document.body).on('click', '.quantity_swal__minus', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            let input = $(this).closest('.quantity_swal').find('input[type="number"]');
            let min = input.attr('min');
            let max = input.attr('max');
            let oldValue = parseFloat(input.val());

            if (oldValue <= max) {
                let newVal = oldValue;
            } else {
                let newVal = oldValue - 1;
            }

            if (newVal >= 1) {
                input.val(newVal);
                input.trigger("change");
            }
        });


        let open_popups = document.querySelectorAll('.courses-popup__open');

        open_popups.forEach((popup) => {
            popup.addEventListener('click', function () {
                let url = this.getAttribute('data-url'),
                    title = this.getAttribute('data-title'),
                    trainingId = this.getAttribute('data-training');

                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire({
                            title: title,
                            html: data.popup_html,
                            showCloseButton: true,
                            showConfirmButton: false,
                            showCancelButton: false,
                            onOpen: () => {

                                const trainingInput = document.querySelector('#training-id');
                                if (trainingInput) {
                                    trainingInput.value = trainingId;
                                }

                                const form = document.querySelector('#courses-form');
                                if (form) {

                                    intlTelInputInit();
                                    form.addEventListener("submit", function (event) {
                                        event.preventDefault();

                                        let formData = new FormData(form);

                                        fetch(form.action, {
                                            method: 'POST',
                                            body: formData,
                                            headers: {
                                                'X-Requested-With': 'XMLHttpRequest',
                                                'X-CSRFToken': getCookie('csrftoken') // Если CSRF-токен требуется
                                            }
                                        })
                                            .then(response => response.json())
                                            .then(data => {
                                                if (data.success) {
                                                    Swal.fire({
                                                        title: 'Success!',
                                                        text: data.message,
                                                        icon: 'success',
                                                        showCloseButton: true,
                                                        confirmButtonText: 'OK'
                                                    }).then(() => {
                                                        window.location.href = data.redirect_url;
                                                    });
                                                } else {
                                                    // Отображение ошибок формы
                                                    let errorsHtml = '<ul>';
                                                    Object.keys(data.errors).forEach(key => {
                                                        errorsHtml += `<li>${data.errors[key].join(', ')}</li>`;
                                                    });
                                                    errorsHtml += '</ul>';
                                                    Swal.fire({
                                                        title: 'Error!',
                                                        html: errorsHtml,
                                                        icon: 'error'
                                                    });
                                                }
                                            })
                                            .catch(error => console.error('Error in form submission:', error));
                                    });
                                } else {
                                    console.warn('Form with id #courses-form not found');
                                }
                            }
                        });
                    })
                    .catch(error => console.error('Error fetching popup HTML:', error));

            });
        });

        $('#catering-popup').on("click", function (e) {
            e.preventDefault();
            let url = this.getAttribute('data-url')
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    Swal.fire({
                        html: data.popup_html,
                        showCloseButton: true,
                        showConfirmButton: false,
                        showCancelButton: false,
                        onOpen: () => {

                            const form = document.querySelector('#catering-form');
                            if (form) {

                                intlTelInputInit();
                                date_picker_init();
                                form.addEventListener("submit", function (event) {
                                    event.preventDefault();
                                    let formData = new FormData(form);

                                    fetch(form.action, {
                                        method: 'POST',
                                        body: formData,
                                        headers: {
                                            'X-Requested-With': 'XMLHttpRequest',
                                            'X-CSRFToken': getCookie('csrftoken') // Если CSRF-токен требуется
                                        }
                                    })
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.success) {
                                                Swal.fire({
                                                    title: 'Success!',
                                                    text: data.message,
                                                    icon: 'success',
                                                    showCloseButton: true,
                                                    confirmButtonText: 'OK'
                                                }).then(() => {
                                                    window.location.href = data.redirect_url;
                                                });
                                            } else {
                                                // Отображение ошибок формы
                                                let errorsHtml = '';
                                                Object.keys(data.errors).forEach(key => {
                                                    errorsHtml += `${key}: ${data.errors[key].join(', ')}\n`;
                                                });
                                                alert(errorsHtml);
                                            }
                                        })
                                        .catch(error => console.error('Error in form submission:', error));
                                });
                            } else {
                                console.warn('Form with id #catering-form not found');
                            }
                        }
                    });
                })
                .catch(error => console.error('Error fetching popup HTML:', error));
        });


        // Progress bar
        function updateProgressBar() {
            const progressBar = document.querySelector('.progress-bar');
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const progress = (window.pageYOffset / totalHeight) * 100;
            progressBar.style.width = progress + '%';
        }

        updateProgressBar();
        window.addEventListener('scroll', updateProgressBar);
        window.addEventListener('resize', updateProgressBar);

        $.fn.sectionMenuInViewport = function () {
            let elementTop = $(this).offset().top;
            let elementBottom = elementTop + $(this).outerHeight() / 2;
            let viewportTop = $(window).scrollTop();
            let viewportBottom = viewportTop + $(window).height() / 2;
            return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        function dotsMenu() {
            let sections = $('.dot_menu_item');
            $.each(sections, function (index, value) {
                if ($(value).sectionMenuInViewport()) {

                    $('.main-nav li a').removeClass('active')
                    $('.main-nav li a').each(function (i, val) {
                        if (index === i) {
                            $(val).addClass('active');
                        }
                    });
                }

            });

        }

        dotsMenu();
        $(window).on('resize scroll', function () {
            dotsMenu();
        });

        $('.navbar_toggler').on('click', function () {
            $('.header__nav').addClass('active')
            $('body').addClass('hidden_body');
            $('.js_nav_open').show();
        })

        $('.header__nav_close').on('click', function () {
            $('.header__nav').removeClass('active')
            $('body').removeClass('hidden_body');
            $('.js_nav_open').hide();
        })

        $(".js_nav_open").on('click', function () {
            $('body').removeClass('hidden_body');
            $(this).hide();
            $('.header__nav').removeClass('active');
        });

        $('.header__nav a').on('click', function () {
            $('.header__nav').removeClass('active')
            $('body').removeClass('hidden_body');
            $('.js_nav_open').hide();
        })

        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('.back-to-top').fadeIn('slow');
            } else {
                $('.back-to-top').fadeOut('slow');
            }
        });
        $('.back-to-top').click(function () {
            $('html, body').animate({scrollTop: 0}, 1500,);
            return false;
        });

        $('.anchor[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                let target = $(this.hash);
                let menuHeight = $('header').outerHeight();
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });


        const gallerySwiperSelector = document.querySelector('.gallery__slider');
        const gallerySwiper = new Swiper(gallerySwiperSelector, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            loop: true,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                576: {
                    spaceBetween: 24,
                    slidesPerView: 2,
                    roundLengths: true,
                    centeredSlides: true,
                },
                768: {
                    spaceBetween: 36,
                    slidesPerView: 2,
                    roundLengths: true,
                    centeredSlides: true,
                },
                1200: {
                    spaceBetween: 60,
                    slidesPerView: 'auto',
                    roundLengths: true,
                    centeredSlides: true,
                }
            }
        });
    });
}(jQuery));